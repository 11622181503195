import { IGenuinePart } from '@/types/shared/parts';

const getMonth = (month) => {
  switch (month) {
    case 0:
      return 'ม.ค.';
    case 1:
      return 'ก.พ.';
    case 2:
      return 'มี.ค.';
    case 3:
      return 'เม.ย.';
    case 4:
      return 'พ.ค.';
    case 5:
      return 'มิ.ย.';
    case 6:
      return 'ก.ค.';
    case 7:
      return 'ส.ค.';
    case 8:
      return 'ก.ย.';
    case 9:
      return 'ต.ค.';
    case 10:
      return 'พ.ย.';
    case 11:
      return 'ธ.ค.';
  }
};

const getMonthFull = (month) => {
  switch (month) {
    case 0:
      return 'มกราคม';
    case 1:
      return 'กุมภาพันธ์';
    case 2:
      return 'มีนาคม';
    case 3:
      return 'เมษายน';
    case 4:
      return 'พฤษภาคม';
    case 5:
      return 'มิถุนายน';
    case 6:
      return 'กรกฎาคม';
    case 7:
      return 'สิงหาคม';
    case 8:
      return 'กันยายน';
    case 9:
      return 'ตุลาคม';
    case 10:
      return 'พฤศจิกายน';
    case 11:
      return 'ธันวาคม';
  }
};

export const formatDate = (date) => {
  const _date = new Date(date);

  return `${_date.getUTCDate()} ${getMonth(_date.getUTCMonth())} ${
    _date.getUTCFullYear() + 543
  }`;
};

export const formatDateFull = (date) => {
  const _date = new Date(date);

  return `${_date.getUTCDate()} ${getMonthFull(_date.getUTCMonth())} ${
    _date.getUTCFullYear() + 543
  }`;
};

export const formatTel = (tel, stripDash = false) => {
  let formatTel = tel;
  if (tel[0] != '0') {
    formatTel = `0${tel}`;
  }

  if (stripDash) {
    formatTel = formatTel.split('-')[0];
  }

  return formatTel;
};

export const youtubeURL = (id: string) => {
  return `https://www.youtube.com/embed/${id}/?autoplay=1&rel=0&modestbranding=1`;
};

export const changeDashToUnderscore = (text: string) => {
  return text.replace(/-/g, '_');
};

export const removeSpan = (text: string) => {
  return text.replace(/<\/?span[^>]*>/g, '');
};

export const removeP = (text: string) => {
  return text.replace(/<\/?p[^>]*>/g, '');
};

export const removeHTMLTag = (text = '') => {
  return text.replace(/<\/?[^>]+(>|$)/g, '');
};

export const removeEmptyTags = (text: string) => {
  // Remove <p> tags
  const withoutEmptyPTags = text.replace(/<p\b[^>]*>\s*<\/p>/gi, '');

  // Remove <span> tags
  const withoutEmptySpanTags = withoutEmptyPTags.replace(
    /<span\b[^>]*>\s*<\/span>/gi,
    ''
  );
  // Remove <br /> tags
  const withoutBrTags = withoutEmptySpanTags.replace(/<br\s*\/?>/gi, '');

  return withoutBrTags;
};

// Remove empty <p> tags
export const removeEmptyParagraphTags = (text: string) => {
  const withoutEmptyPTags = text.replace(/<p\b[^>]*>\s*<\/p>/gi, '');

  return withoutEmptyPTags;
};

export const removeBrTags = (text: string) => {
  // Remove <br /> tags
  const withoutBrTags = text.replace(/<br\s*\/?>/gi, '');

  return withoutBrTags;
};

export const formatPrice = (val, unit = true) => {
  const num: number = +val;
  return (
    (unit ? 'THB ' : '') +
    num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  );
};

export const removeHash = (text: string) => {
  return text.replace('#', '');
};

export const removeWhitespace = (text: string) => {
  return text.replace(/\s/g, '');
};

export const camelCase = (text: string) => {
  return text.replace(/-([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
};

export const formatTextToLowerCaseWithUnderscores = (text: string) => {
  // Convert text to lowercase
  const lowercaseText = text.toLowerCase();

  // Replace whitespace with underscores
  const processedText = lowercaseText.replace(/\s/g, '_');

  return processedText;
};

export const formatTextToLowerCaseWithDash = (text: string) => {
  // Convert text to lowercase
  const lowercaseText = text.toLowerCase();

  // Replace whitespace with underscores
  const processedText = lowercaseText.replace(/\s/g, '-');

  return processedText;
};

export const removeAsterisk = (text: string) => {
  return text.replace(/[*]/g, '');
};

export const formatNumber = (val, decimal = 0) => {
  return Number(val).toFixed(decimal);
};

export const cleanUrl = (val) => {
  return val.replace('//', '/');
};

export const removeDuplicateDot = (value) => {
  while (value.split('.').length > 2) {
    const lastIndex = value.lastIndexOf('.');
    value = `${value.substring(0, lastIndex)}${value.substring(lastIndex + 1)}`;
  }
  return value;
};

export const limitFloat = (value, decimalNumber) => {
  if (
    value.split('.').length == 2 &&
    value.split('.')[1].length > decimalNumber
  ) {
    let floatValue = value.split('.')[1];
    floatValue = floatValue.substr(0, decimalNumber);
    value = `${value.split('.')[0]}.${floatValue}`;
  }
  return value;
};

export const limit2Float = (value) => {
  if (value.split('.').length == 2 && value.split('.')[1].length > 2) {
    let floatValue = value.split('.')[1];
    floatValue = floatValue.substr(0, 2);
    value = `${value.split('.')[0]}.${floatValue}`;
  }
  return value;
};

export const getValidatedPaymentValue = (value: string, price?: string) => {
  let result = value;
  result = value.replace(/[^0-9]/, '');
  result = value.replaceAll(',', '');
  if (price && parseFloat(result) > parseFloat(price)) {
    return price.toString();
  }
  return result;
};

export const getPartPrefix = (part: IGenuinePart) => {
  let prefix = part.isIsuzuGenuinePart
    ? 'อะไหล่แท้<span>อีซูซุ</span>'
    : part.isTripetchGenuinePart
    ? 'อะไหล่แท้<span>ตรีเพชร</span>'
    : part.isBvpPart
    ? 'อีซูซุเบสท์<span>แวรลู</span>'
    : part.title && part.title.length > 0
    ? part.title
    : '';

  prefix = part.isStartPrice ? `${prefix}` : prefix;

  return prefix;
};

export const normaliseToArray = (value: string | string[]): string[] => {
  if (typeof value === 'string') {
    return [value];
  } else if (Array.isArray(value)) {
    return value;
  }
  return [];
};

export const formatThaiDateTime = (value) => {
  const now = new Date(value).toLocaleString('en-GB', {
    timeZone: 'Asia/Bangkok',
    hour12: false,
  });
  const [date, time] = now.split(', ');
  const [day, month, year] = date.split('/');
  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = time;
  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return formattedDateTime;
};
