import NextLink from 'next/link';
import React, { ElementType } from 'react';

import { removeSpan } from '@/components/shared/utility/format';
import { styled } from '@/stitches.config';
import { ITest, ITracking } from '@/types/tracking';

const StyledLink = styled('div', {
  color: 'inherit',
  textDecoration: 'none',
  variants: {
    clickable: {
      true: {
        position: 'absolute',
        left: '$space-0',
        right: '$space-0',
        top: '$space-0',
        bottom: '$space-0',
      },
    },
    hoverColor: {
      white: {
        '@hover': {
          '&:hover': {
            '& *': {
              color: '$white',
              '& span': {
                color: 'inherit',
              },
            },
          },
        },
      },
      red: {
        '@hover': {
          '&:hover': {
            '& *': {
              color: '$primary',
              '& span': {
                color: 'inherit',
              },
            },
          },
        },
      },
      gray: {
        '@hover': {
          '&:hover': {
            '& *': {
              color: '$gray400',
              '& span': {
                color: 'inherit',
              },
            },
          },
        },
      },
    },
    underline: {
      true: {
        '&, & *': {
          color: '$primary',
          textDecoration: 'underline',
          'text-underline-position': 'from-font',
          transitionProperty: 'color, transform, opacity',
        },
        '@hover': {
          '&:hover': {
            '&, & *': {
              color: '$primary100',
            },
          },
        },
      },
    },
  },
});

interface LinkProps extends React.ComponentProps<typeof StyledLink> {
  /**
   * The component to be rendered.
   */
  component?: ElementType;
  children?: React.ReactNode;
  title?: string;
  href?: string;
  target?: '_self' | '_blank' | '_parent' | '_top';
  prefetch?: boolean;
  onClick?: () => void;
  tracking?: ITracking;
  test?: ITest;
  clickable?: boolean;
  hoverColor?: 'white' | 'red' | 'gray';
  underline?: boolean;
  css?: any;
  className?: string;
  onMouseOver?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const Link: React.FC<LinkProps> = ({
  component = 'a',
  children,
  title,
  href,
  target,
  prefetch = false,
  clickable = false,
  hoverColor,
  underline = false,
  onClick,
  onMouseOver,
  onMouseEnter,
  onMouseLeave,
  tracking,
  test,
  css,
  className,
}: LinkProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick && onClick();
  };

  const handleMouseOver = () => {
    onMouseOver && onMouseOver();
  };

  const dataTrackText = () => {
    return tracking?.dataTrackText ? removeSpan(tracking.dataTrackText) : null;
  };
  return (
    <>
      {href ? (
        <NextLink href={href} prefetch={prefetch} legacyBehavior>
          <StyledLink
            as={component}
            aria-label={title}
            href={href}
            target={target}
            rel={target === '_blank' ? 'noopener noreferrer' : undefined}
            underline={underline}
            hoverColor={hoverColor}
            clickable={clickable}
            data-track={tracking?.dataTrack}
            data-track-section={tracking?.dataTrackSection}
            data-track-value={tracking?.dataTrackValue}
            data-track-text={dataTrackText()}
            data-track-url={tracking?.dataTrackUrl}
            data-test={test?.dataTest}
            data-test-tag={test?.dataTestTag}
            data-test-update-date={test?.dataTestUpdateDate}
            data-test-pin={test?.dataTestPin}
            css={css}
            className={className}
            onMouseOver={handleMouseOver}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {children}
          </StyledLink>
        </NextLink>
      ) : (
        <StyledLink
          clickable={clickable}
          onClick={handleClick}
          onMouseOver={handleMouseOver}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          data-track={tracking?.dataTrack}
          data-track-section={tracking?.dataTrackSection}
          data-track-value={tracking?.dataTrackValue}
          data-track-text={dataTrackText()}
          data-track-url={tracking?.dataTrackUrl}
          data-test={test?.dataTest}
          data-test-tag={test?.dataTestTag}
          data-test-update-date={test?.dataTestUpdateDate}
          data-test-pin={test?.dataTestPin}
          css={css}
          className={className}
        >
          {children}
        </StyledLink>
      )}
    </>
  );
};

Link.displayName = 'TextLink';
